<template>
  <div class="flex flex-col gap-y-2 w-full px-5 my-5">
    <div
      class="bg-white flex flex-col gap-y-5 items-center justify-center py-6 rounded-lg"
    >
      <div class="flex flex-col gap-y-2 items-center justify-center">
        <div class="w-24 h-24 rounded bg-red-500"></div>
        <h1 class="font-EffraM text-black text-base">Abderrahman Nazih</h1>
        <span class="text-labelGray font-EffraR text-sm">g8k@hotmail.fr</span>
      </div>

      <a
        href="#"
        class="font-EffraM text-rosDok-ultra uppercase flex items-center gap-x-2"
        @click="currentPage = 1"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          fill="none"
          viewBox="0 0 18 18"
        >
          <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M12 2.25L15.75 6 6 15.75H2.25V12L12 2.25z"
          />
        </svg>
        Modifier mon compte
      </a>
    </div>

    <div
      class="bg-white py-5 px-4 flex items-center justify-between rounded-lg border border-solid border-borderInput"
      @click="currentPage = 2"
    >
      <div class="flex items-center gap-x-1">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          fill="none"
          viewBox="0 0 20 20"
        >
          <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M15.833 3.333H4.167C3.247 3.333 2.5 4.079 2.5 5v11.666c0 .92.746 1.667 1.667 1.667h11.666c.92 0 1.667-.746 1.667-1.667V5c0-.92-.746-1.667-1.667-1.667zM13.333 1.667V5M6.667 1.667V5M2.5 8.333h15"
          />
        </svg>
        <div id="title" class="font-EffraR text-base">Mes Rendez-vous</div>
      </div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        fill="none"
        viewBox="0 0 20 20"
      >
        <path
          stroke="#111"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M7.5 15l5-5-5-5"
        />
      </svg>
    </div>
    <div
      class="bg-white py-5 px-4 flex items-center justify-between rounded-lg border border-solid border-borderInput"
      @click="currentPage = 3"
    >
      <div class="flex items-center gap-x-1">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          fill="none"
          viewBox="0 0 20 20"
        >
          <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M3.333 3.333h13.334c.916 0 1.666.75 1.666 1.667v10c0 .916-.75 1.666-1.666 1.666H3.333c-.916 0-1.666-.75-1.666-1.666V5c0-.917.75-1.667 1.666-1.667z"
          />
          <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M18.333 5L10 10.833 1.667 5"
          />
        </svg>

        <div id="title" class="font-EffraR text-base">
          Email et mot de passe
        </div>
      </div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        fill="none"
        viewBox="0 0 20 20"
      >
        <path
          stroke="#111"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M7.5 15l5-5-5-5"
        />
      </svg>
    </div>
    <div
      class="bg-white py-5 px-4 flex items-center justify-between rounded-lg border border-solid border-borderInput"
      @click="currentPage = 4"
    >
      <div class="flex items-center gap-x-1">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          fill="none"
          viewBox="0 0 20 20"
        >
          <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M11.442 17.5c-.147.253-.357.462-.61.608-.253.146-.54.223-.832.223-.292 0-.579-.077-.832-.223-.253-.146-.463-.355-.61-.608m9.775-3.333H1.667c.663 0 1.299-.263 1.767-.732.47-.47.733-1.105.733-1.768V7.5c0-1.547.614-3.03 1.708-4.124C6.97 2.282 8.453 1.667 10 1.667c1.547 0 3.03.615 4.125 1.709 1.094 1.094 1.708 2.577 1.708 4.124v4.167c0 .663.264 1.299.732 1.768.47.469 1.105.732 1.768.732z"
          />
        </svg>

        <div id="title" class="font-EffraR text-base">Notifications</div>
      </div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        fill="none"
        viewBox="0 0 20 20"
      >
        <path
          stroke="#111"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M7.5 15l5-5-5-5"
        />
      </svg>
    </div>

    <div
      class="bg-white py-5 px-4 flex items-center justify-between rounded-lg border border-solid border-borderInput"
    >
      <div class="flex items-center gap-x-1">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          fill="none"
          viewBox="0 0 20 20"
        >
          <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M7.5 17.5H4.167c-.442 0-.866-.176-1.179-.488-.312-.313-.488-.737-.488-1.179V4.167c0-.442.176-.866.488-1.179.313-.312.737-.488 1.179-.488H7.5M13.333 14.166L17.5 10l-4.167-4.167M17.5 10h-10"
          />
        </svg>
        <div id="title" class="font-EffraR text-base">Se deconnecter</div>
      </div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        fill="none"
        viewBox="0 0 20 20"
      >
        <path
          stroke="#111"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M7.5 15l5-5-5-5"
        />
      </svg>
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/event-bus";

export default {
  props: {
    page: {
      type: Number,
      required: true
    }
  },
  computed: {
    currentPage: {
      get() {
        return this.page;
      },
      set(number) {
        EventBus.$emit("Change_Page_Number_My_Account_Patient", number);
      }
    }
  }
};
</script>

<style></style>
